import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/Login";
import ThemeChanger from "./components/ThemeChanger";
import Profile from "./components/Page/Profile";
import Template from "./components/Page/Template";
import Dashboard from "./components/Page/Dashboard";
import AddCommodity from "./components/Page/AddCommodity";
import CommodityList from "./components/Page/CommodityList";
import AddCommodityName from "./components/Page/AddCommodityType";
import CommodityTypeList from "./components/Page/CommodityTypeList";
// import Navbar from "./components/Navbar";
// import Sidebar from "./components/Sidebar";

function App() {
  const [theme, setTheme] = useState("light");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const toggleTheme = (newTheme) => {
    setTheme(newTheme);
  };

  // Handle successful login
  const handleLogin = (token) => {
    localStorage.setItem("authToken", token); 
    setIsLoggedIn(true); 
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <Router>
      <div className={`min-h-screen ${theme === "dark" ? "bg-gray-900 text-white" : "bg-white text-black"}`}>
        <ThemeChanger theme={theme} toggleTheme={toggleTheme} />

        {isLoggedIn ? (
          <>
            {/* <Navbar />
            <div className="flex">
              <Sidebar /> */}
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/addCommodity" element={<AddCommodity />} />
                  <Route path="/commodityList" element={<CommodityList />} />
                  <Route path="/addCommodityName" element={<AddCommodityName />} />
                  <Route path="/commodityTypeList" element={<CommodityTypeList />} />
                  {/* <Route path="*" element={<Navigate to="/" />} />  */}
                </Routes>
                {/* </div> */}
            {/* <button
              onClick={handleLogout}
              className="fixed top-4 right-4 bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded shadow-lg"
            >
              Logout
            </button> */}
          </>
        ) : (
          <Routes>
            {/* <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="*" element={<Navigate to="/login" />} /> */}
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;
