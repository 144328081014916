import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEdit, FaTrash } from "react-icons/fa";
import { baseUrl } from "../../BaseUrl";
import Template from "./Template";

const CommodityList = () => {
  const [commodities, setCommodities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [editCommodity, setEditCommodity] = useState(null);
  const [message, setMessage] = useState('');  

  // Handle opening the edit form and setting commodity data
  const handleEdit = (commodity) => {
    setEditCommodity({ ...commodity });
  };

  // Handle saving the updated commodity data
  const handleSaveEdit = async () => {
    if (!editCommodity) return;

    const token = localStorage.getItem("authToken"); // Get token from localStorage

    try {
      const response = await axios.put(
        `${baseUrl}/admin/updateCommodityType/${editCommodity.commodity_type_id}`,
        {
          commodity_type_name: editCommodity.commodity_type_name,
          isActive: editCommodity.isActive,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update state with the updated commodity
      setCommodities((prevCommodities) =>
        prevCommodities.map((commodity) =>
          commodity.commodity_type_id === editCommodity.commodity_type_id
            ? { ...commodity, ...editCommodity }
            : commodity
        )
      );
      setEditCommodity(null); // Close the edit form after saving
    } catch (error) {
      console.error("Error saving commodity:", error);
    }
  };

  // Handle closing the edit form without saving
  const handleCancelEdit = () => {
    setEditCommodity(null); // Close the edit form
  };

  useEffect(() => {
    const fetchCommodities = async () => {
      try {
        const response = await axios.get(`${baseUrl}/admin/getCommodityType`);
        setCommodities(response.data.data);
      } catch (error) {
        console.error("Error fetching commodities:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCommodities();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    // Handle search logic here
  };

  const handleDelete = async (commodityId) => {
    const token = localStorage.getItem('authToken'); // Retrieve token from localStorage

    try {
      const response = await axios.delete(
        `${baseUrl}/admin/removeCommodityType/${commodityId}`,
        {
          headers: {
            Authorization: `Bearer ${token}` // Pass token in headers
          }
        }
      );
      setMessage('Commodity added successfully!');
      setTimeout(() => {
        setMessage('');
      }, 2000);

      setCommodities(commodities.filter(commodity => commodity.commodity_type_id !== commodityId));
    } catch (error) {
      console.error("Error deleting commodity:", error);
    }
  };
      

  return (
    <Template>
      <div className="flex justify-between items-center pb-4 p-8 bg-green-50">
        <h1 className="text-2xl font-semibold text-black">Commodity</h1>
        <button className="bg-green-500 text-white px-4 py-2 rounded-2xl font-bold shadow hover:bg-green-600">
          + Add Commodity
        </button>
      </div>
      {message && (
            <div
              className={`p-2 mb-4 rounded text-center font-semibold ${
                message.startsWith('Commodity added')
                  ? 'bg-green-100 text-green-600'
                  : 'bg-red-100 text-red-600'
              }`}
            >
              {message}
            </div>
          )}
      <div className="min-h-screen bg-green-50 p-6">
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex justify-between items-center  p-4 rounded-t-lg shadow">
            <div className="text-gray-700">
              <label htmlFor="entries" className="text-sm font-medium">
                Show Entries:
              </label>
              <select
                id="entries"
                className="ml-2 border border-gray-300 rounded-md p-1 text-sm"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>

            <div className="flex items-center">
              <label htmlFor="search" className="text-sm font-medium text-gray-700 mr-2">
                Search:
              </label>
              <input
                type="text"
                id="search"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Type to search..."
                className="border border-gray-300 rounded-md p-4 text-sm focus:ring-2 focus:ring-green-400 focus:outline-none"
              />
            </div>
          </div>
          <div className="overflow-x-auto">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <table className="w-full border-collapse border border-gray-200 text-sm text-left">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="border border-gray-200 px-4 py-2">S.No</th>
                    <th className="border border-gray-200 px-4 py-2">Commodity Name</th>
                    <th className="border border-gray-200 px-4 py-2">Active Status</th>
                    <th className="border border-gray-200 px-4 py-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {commodities.map((commodity, index) => (
                    <tr key={commodity.commodity_type_id} className="odd:bg-white even:bg-gray-50">
                      <td className="border border-gray-200 px-4 py-2 text-center">
                        {index + 1}
                      </td>
                      <td className="border border-gray-200 px-4 py-2">
                        {commodity.commodity_type_name}
                      </td>
                      <td className="border border-gray-200 px-4 py-2 text-center">
                        {commodity.isActive ? (
                          <span className="text-green-600 font-bold">Active</span>
                        ) : (
                          <span className="text-red-600 font-bold">Inactive</span>
                        )}
                      </td>
                      <td className="border border-gray-200 px-4 py-2 text-center">
                        <button
                          className="text-blue-500 hover:text-blue-700 mx-2"
                          onClick={() => handleEdit(commodity)}
                        >
                          <FaEdit />
                        </button>
                        <button
                          className="text-red-500 hover:text-red-700 mx-2"
                          onClick={() => handleDelete(commodity.commodity_type_id)}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          {/* Edit Modal */}
          {editCommodity && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl mb-4">Edit Commodity</h2>
            <div className="mb-4">
              <label htmlFor="commodity-name" className="text-sm font-medium">
                Commodity Name
              </label>
              <input
                id="commodity-name"
                type="text"
                value={editCommodity.commodity_type_name}
                onChange={(e) =>
                  setEditCommodity({
                    ...editCommodity,
                    commodity_type_name: e.target.value,
                  })
                }
                className="border border-gray-300 rounded-md p-2 text-sm w-full"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="isActive" className="text-sm font-medium">
                Active Status
              </label>
              <select
                id="isActive"
                value={editCommodity.isActive ? "true" : "false"}
                onChange={(e) =>
                  setEditCommodity({
                    ...editCommodity,
                    isActive: e.target.value === "true",
                  })
                }
                className="border border-gray-300 rounded-md p-2 text-sm w-full"
              >
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>
            <div className="flex justify-between">
              <button
                className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                onClick={handleSaveEdit}
              >
                Save Changes
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                onClick={handleCancelEdit} // Close the form without saving
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

        </div>
      </div>
    </Template>
  );
};

export default CommodityList;
