import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from "../../BaseUrl"; 
import { FaEdit, FaTrash } from 'react-icons/fa';
import Template from './Template';

const CommodityTypeList = () => {
  const [commodityTypes, setCommodityTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchCommodityTypes = async () => {
      try {
        const response = await axios.get(`${baseUrl}/admin/getAllCommodityName`);
        setCommodityTypes(response.data.data);
      } catch (error) {
        console.error("Error fetching commodity types:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCommodityTypes();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredCommodities = commodityTypes.filter((commodity) =>
    commodity.commodityType && commodity.commodityType.commodity_type_name && 
    commodity.commodityType.commodity_type_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDelete = async (commodityId) => {
    const token = localStorage.getItem('authToken'); 

    try {
      const response = await axios.delete(
        `${baseUrl}/admin/removeCommodityName/${commodityId}`,
        {
          headers: {
            Authorization: `Bearer ${token}` 
          }
        }
      );
      setMessage('Commodity Type removed successfully!');
      setTimeout(() => {
        setMessage('');
      }, 2000);
      setCommodityTypes(commodityTypes.filter(commodity => commodity.commodity_id !== commodityId));
    } catch (error) {
      console.error("Error deleting commodity type:", error);
    }
  };

  return (
    <Template>
          <div className="flex justify-between items-center pb-4 p-8 bg-green-50">
        <h1 className="text-2xl font-semibold text-black">Commodity</h1>
        <button className="bg-green-500 text-white px-4 py-2 rounded-2xl font-bold shadow hover:bg-green-600">
          + Add Commodity
        </button>
      </div>
      {message && (
            <div
              className={`p-2 mb-4 rounded text-center font-semibold ${
                message.startsWith('Commodity name added')
                  ? 'bg-green-100 text-green-600'
                  : 'bg-red-100 text-red-600'
              }`}
            >
              {message}
            </div>
          )}
      <div className="min-h-screen bg-green-50 p-6">
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex justify-between items-center p-4 rounded-t-lg shadow">
            <div className="text-gray-700">
              <label htmlFor="entries" className="text-sm font-medium">
                Show Entries:
              </label>
              <select
                id="entries"
                className="ml-2 border border-gray-300 rounded-md p-1 text-sm"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>

            <div className="flex items-center">
              <label htmlFor="search" className="text-sm font-medium text-gray-700 mr-2">
                Search:
              </label>
              <input
                type="text"
                id="search"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Search commodity types..."
                className="border border-gray-300 rounded-md p-4 text-sm focus:ring-2 focus:ring-green-400 focus:outline-none"
              />
            </div>
          </div>

          {message && (
            <div
              className={`p-2 mb-4 rounded text-center font-semibold ${
                message.startsWith('Commodity Type removed')
                  ? 'bg-green-100 text-green-600'
                  : 'bg-red-100 text-red-600'
              }`}
            >
              {message}
            </div>
          )}

          <div className="overflow-x-auto">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <table className="w-full border-collapse border border-gray-200 text-sm text-left">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="border border-gray-200 px-4 py-2">S.No</th>
                    <th className="border border-gray-200 px-4 py-2">Commodity Name</th>
                    <th className="border border-gray-200 px-4 py-2">Commodity Type</th>
                    <th className="border border-gray-200 px-4 py-2">Active Status</th>
                    <th className="border border-gray-200 px-4 py-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCommodities.map((commodity, index) => (
                    <tr key={commodity.commodity_id} className="odd:bg-white even:bg-gray-50">
                      <td className="border border-gray-200 px-4 py-2 text-center">
                        {index + 1}
                      </td>
                      <td className="border border-gray-200 px-4 py-2">
                        {commodity.commodity_name}
                      </td>
                      <td className="border border-gray-200 px-4 py-2">
                        {commodity.commodityType.commodity_type_name}
                      </td>
                      <td className="border border-gray-200 px-4 py-2 text-center">
                        {commodity.commodityType.isActive ? (
                          <span className="text-green-600 font-bold">Active</span>
                        ) : (
                          <span className="text-red-600 font-bold">Inactive</span>
                        )}
                      </td>
                      <td className="border border-gray-200 px-4 py-2 text-center">
                        <button
                          className="text-blue-500 hover:text-blue-700 mx-2"
                          // onClick={() => handleEdit(commodity)}
                        >
                          <FaEdit />
                        </button>
                        <button
                          className="text-red-500 hover:text-red-700 mx-2"
                          onClick={() => handleDelete(commodity.commodity_id)}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </Template>
  );
};

export default CommodityTypeList;
