import React from 'react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';

function Template({ children }) {
  return (
    <>
      <Navbar />
      <div className="flex ">
        <div className="min-w-[300px]">
          <Sidebar />
        </div>
        <div className="flex-grow">
          {children}
          <h1 className='text-center text-gray-600'>Copyright © 2024 The AgroMart. All rights reserved

</h1>
        </div>
      </div>
    </>
  );
}

export default Template;
