import React from "react";
import logo from "../../assets/Logo.png";
import profile from "../../assets/profile.jpeg";
import Template from "./Template";

function Profile() {
  return (
    <Template>
      <div className="min-h-screen p-8 bg-green-50">
        <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="flex items-center gap-6 p-6 border-b">
            <div className="w-20 h-20">
              <img src={logo} alt="logo" className="object-cover" />
            </div>

            <div>
              <img src={profile} alt="profile" className="w-20 h-20 object-cover rounded-full" />
              <h1 className="text-2xl font-bold text-gray-700">Cool Admin</h1>
              <p className="text-gray-500">Software Developer</p>
            </div>

            <button className="ml-auto px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700">
              Edit Profile
            </button>
          </div>
          <div className="p-6">
            <h1 className="text-xl font-semibold text-green-700 mb-4">About Us</h1>
            <p className="mb-4 text-gray-600">
              "Mandi vegetables" typically refer to the variety of fresh produce found at a mandi (market),
              which is a traditional wholesale market where farmers and vendors sell vegetables, fruits, and
              other agricultural products. These vegetables are often sourced directly from the farms and are
              available at reasonable prices, making them a popular choice for buyers, including households,
              restaurants, and retailers.
            </p>
            <h2 className="text-xl font-semibold text-green-700 mb-4">Personal Information</h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-500">Full Name</label>
                <p className="text-lg text-gray-700">Cool Admin</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-500">Email</label>
                <p className="text-lg text-gray-700">cool.admin@example.com</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-500">Phone</label>
                <p className="text-lg text-gray-700">+123 456 7890</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-500">Address</label>
                <p className="text-lg text-gray-700">123, Cool Street, City, Country</p>
              </div>
            </div>
            <button className="mt-6 px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
              Edit Personal Info
            </button>
          </div>
        </div>
      </div>
    </Template>
  );
}

export default Profile;
