import React, { useState } from 'react';
import { RiAuctionLine, RiDashboardHorizontalLine } from "react-icons/ri";
import { FaHospitalUser } from "react-icons/fa";
import { MdOutlineKeyboardArrowRight } from "react-icons/md"; 

function Sidebar() {
  const [isOpen, setIsOpen] = useState(true);
  const [activeLink, setActiveLink] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); 
  const [isCommodityTypeDropdownOpen, setIsCommodityTypeDropdownOpen] = useState(false); // New state for Commodity Type dropdown

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleCommodityTypeDropdown = () => {
    setIsCommodityTypeDropdownOpen(!isCommodityTypeDropdownOpen); // Toggle Commodity Type dropdown
  };

  const handleLinkClick = (link) => {
    setActiveLink(link); 
  };

  return (
    <div className={`h-screen bg-white flex flex-col shadow-2xl text-gray-400 transition-all duration-300`}>
      <button
        onClick={toggleSidebar}
        className="absolute top-4 right-4 md:hidden text-gray-600"
      >
        {isOpen ? 'Close' : 'Open'}
      </button>

      <ul className="space-y-4 ml-24px">
        <li
          className={`flex items-center gap-4 cursor-pointer ${activeLink === 'dashboard' ? 'bg-green-600 text-white' : 'hover:text-green-600'}`}
          style={{ padding: '11px', borderRadius: '0px 47px 253px 0px' }} 
          onClick={() => handleLinkClick('dashboard')}
        >
          <RiDashboardHorizontalLine size={26} />
          {isOpen && (
            <a href="/dashboard" className="text-xl">
              Dashboard
            </a>
          )}
        </li>

        <li
          className={`flex items-center gap-4 cursor-pointer ${activeLink === 'commodity' ? 'bg-green-600 text-white' : 'hover:text-green-600'}`}
          style={{ padding: '11px', borderRadius: '0px 47px 253px 0px' }}  
          onClick={() => {
            handleLinkClick('commodity'); 
            toggleDropdown(); 
          }}
        >
          <RiAuctionLine size={26} />
          {isOpen && (
            <a href="" className="text-xl">
              Commodity
            </a>
          )}
          <MdOutlineKeyboardArrowRight
            size={32}
            className={`transition-transform  ml-[32px] ${isDropdownOpen ? 'rotate-90' : ''}`}
          />
        </li>
        {isDropdownOpen && (
          <ul className="pl-8 space-y-2">
            <li
              className={`cursor-pointer hover:text-green-600`}
              onClick={() => handleLinkClick('addCommodity')}
            >
              <a href="/addCommodity" className="text-xl">
                Add Commodity
              </a>
            </li>
            <li
              className={`cursor-pointer hover:text-green-600`}
              onClick={() => handleLinkClick('commodityList')}
            >
              <a href="/commodityList" className="text-xl">
                Commodity List
              </a>
            </li>
          </ul>
        )}

        {/* New Dropdown for Commodity Types */}
        <li
          className={`flex items-center gap-4 cursor-pointer ${activeLink === 'commodityTypes' ? 'bg-green-600 text-white' : 'hover:text-green-600'}`}
          style={{ padding: '11px', borderRadius: '0px 47px 253px 0px' }}  
          onClick={() => {
            handleLinkClick('commodityTypes'); 
            toggleCommodityTypeDropdown(); 
          }}
        >
          <RiAuctionLine size={26} />
          {isOpen && (
            <a href="" className="text-xl">
              Commodity Types
            </a>
          )}
          <MdOutlineKeyboardArrowRight
            size={32}
            className={`transition-transform  ml-[32px] ${isCommodityTypeDropdownOpen ? 'rotate-90' : ''}`}
          />
        </li>
        {isCommodityTypeDropdownOpen && (
          <ul className="pl-8 space-y-2">
            <li
              className={`cursor-pointer hover:text-green-600`}
              onClick={() => handleLinkClick('addCommodityType')}
            >
              <a href="/addCommodityName" className="text-xl">
                Add Commodity 
              </a>
            </li>
            <li
              className={`cursor-pointer hover:text-green-600`}
              onClick={() => handleLinkClick('commodityTypeList')}
            >
              <a href="/commodityTypeList" className="text-xl">
                Commodity List
              </a>
            </li>
          </ul>
        )}

        {/* Existing Categories Link */}
        <li
          className={`flex items-center gap-4 cursor-pointer ${activeLink === 'categories' ? 'bg-green-600 text-white' : 'hover:text-green-600'}`}
          style={{ padding: '11px', borderRadius: '0px 47px 253px 0px' }}  
          onClick={() => handleLinkClick('categories')}
        >
          <FaHospitalUser size={26} />
          {isOpen && (
            <a href="/categories" className="text-xl">
              Categories
            </a>
          )}
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
