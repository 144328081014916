import React, { useState } from "react";
import logo from "../assets/Logo.png";
import { FiAlignLeft } from "react-icons/fi";
import profile from "../assets/profile.jpeg";
import { useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { FaLock } from "react-icons/fa";
import { IoIosLogOut } from "react-icons/io";


function Navbar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const handleProfileClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleNavigation = (path) => {
    setIsDropdownOpen(false);
    navigate(path);
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken"); // Clear token
    navigate("/login"); // Redirect to login
  };

  return (
    <div className="w-full shadow-lg bg-white">
      <div className="flex items-center justify-between p-4">
        {/* Left: Logo and Menu Icon */}
        <div className="flex items-center gap-4">
          <div className="w-32">
            <img src={logo} alt="logo" className="h-auto" />
          </div>
          <FiAlignLeft size={28} className="text-green-600 cursor-pointer" />
        </div>

        {/* Right: Profile Section */}
        <div className="relative">
          <div
            className="flex items-center gap-3 p-2 border-2 rounded-xl bg-slate-50 pr-4 cursor-pointer hover:bg-slate-100"
            onClick={handleProfileClick}
          >
            <img
              src={profile}
              alt="profile"
              className="w-14 h-14 rounded-full border-2 border-white"
            />
            <h1 className="text-green-600 font-bold text-lg">Hello, Cool Admin</h1>
          </div>

          {/* Dropdown Menu */}
          {isDropdownOpen && (
            <div className="absolute top-16 right-0 w-48 bg-white border text-gray-500 border-gray-200 rounded-xl shadow-lg z-50">
              <ul className="py-2">
                <li
                  className="flex items-center gap-2 px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleNavigation("/profile")}
                >
                  <CgProfile size={20} className="text-green-500" />
                  <span>Profile</span>
                </li>
                <li
                  className="flex items-center gap-2 px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleNavigation("/change-password")}
                >
                  <FaLock size={20} className="text-blue-500" />
                  <span>Change Password</span>
                </li>
                <li
                  className="flex items-center gap-2 px-4 py-2 hover:bg-red-100 text-red-600 cursor-pointer"
                  onClick={handleLogout}
                >
                  <IoIosLogOut size={20} className="text-red-600" />
                  <span>Logout</span>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
